import { useEffect, useState, useRef } from 'react'
import { useQuery } from 'react-query'
import {
    fetchCommandQueues,
    fetchCommands,
    fetchMonitors,
    transmitCommands,
} from '../API'
import { getToken } from '../API/helpers'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import SvgContainingMonitors from './SvgContainingMonitors'
import LSWidget from '@leanspace/dashboard'
import { API_BASE_URL, TENANT } from '../config'
import { Button, Grid } from '@material-ui/core'

const MonitorAssetPage = () => {
    const [leanspaceWidget, setLeanspaceWidget] = useState(null)
    const elementRef = useRef()
    const [widgetId, setWidgetId] = useState(null)

    const sendCommands = () => {
        transmitCommands({
            commandQueueId: '83eaa5b8-3fc0-4220-8c52-efe1ce9b0469',
            groundStationId: '3e16b138-1afe-44f2-b2e1-ce4cd0a8dad6',
        })
    }

    function setClicked(val) {
        if (leanspaceWidget) {
            leanspaceWidget.destroy()
            setLeanspaceWidget(null)
        }
        if (val === 1) {
            setWidgetId(
                'cf83d613-3853-48f9-92e9-4189aa3ee695/19457806-658c-422c-802c-eb92949b17b7'
            )
        } else if (val === 2) {
            setWidgetId(
                'cf83d613-3853-48f9-92e9-4189aa3ee695/bf88d9cc-fec8-4b2a-8c79-eeb1e93cd98d'
            )
        } else if (val === 3) {
            setWidgetId(
                'cf83d613-3853-48f9-92e9-4189aa3ee695/338ba673-70dd-446a-81bc-ea7156abf391'
            )
        } else if (val === 4) {
            setWidgetId(
                'cf83d613-3853-48f9-92e9-4189aa3ee695/0639b16b-4379-47e3-a698-bd97e5225042'
            )
        }
    }

    // Demonstrator-3
    const assetId = '7a93bfb5-4a35-4ada-93b5-f19cd5a5c893'
    const subAssetId = '36be6279-1e31-43a3-bf3c-961bdf1e5abf'

    function getColor(state) {
        if (state === 'UNKNOWN') {
            return 'grey'
        }
        if (state === 'OK') {
            return 'green'
        }
        if (state === 'TRIGGERED') {
            return 'red'
        }
        return 'white'
    }

    const { isSuccess: monitorsLoadIsSuccess, data: monitors } = useQuery(
        'monitor',
        async () => {
            const response = await fetchMonitors({
                nodeIds: [assetId, subAssetId],
            })
            return response.content
        },
        { refetchInterval: 10000 }
    )

    const antenaState = getColor(
        monitorsLoadIsSuccess
            ? monitors.filter((x) => x.id === 'db06dbe9-914e-47fe-b1c8-f470e9e83687')[0].state
            : 'NONE'
    )
    const rightState = getColor(
        monitorsLoadIsSuccess
            ? monitors.filter((x) => x.id === '7a72ffdf-74b2-47a4-9110-11e321c3999b')[0].state
            : 'NONE'
    )
    const leftState = getColor(
        monitorsLoadIsSuccess
            ? monitors.filter((x) => x.id === 'e19b176e-2754-4989-950e-1b9d6d7affe7')[0].state
            : 'NONE'
    )
    const centerState = getColor(
        monitorsLoadIsSuccess
            ? monitors.filter((x) => x.id === '941c0b95-5afb-425b-a5c4-8ea2c90905b6')[0].state
            : 'NONE'
    )

    useEffect(
        function setupLeanspaceWidget() {
            const setupWidget = async () => {
                if (widgetId !== null) {
                    const token = await getToken()
                    setLeanspaceWidget(
                        LSWidget({
                            element: elementRef.current,
                            apiToken: token,
                            apiUrl: API_BASE_URL,
                            tenantId: TENANT,
                            widgetConfiguration: widgetId,
                            dateRange: 'from 29 minutes ago to now',
                        })
                    )
                }
            }

            if (!leanspaceWidget) {
                setupWidget()
            }
        },
        [widgetId, leanspaceWidget]
    )

    const { isSuccess: commandsLoadIsSuccess, data: commands } = useQuery(
        'commands',
        async () => {
            const response = await fetchCommandQueues({ assetIds: assetId })
            if (response.content.length > 0) {
                const commandQueueId = response.content[0].id
                const commands = await fetchCommands({
                    commandQueueIds: commandQueueId,
                    statuses: 'STAGED',
                })
                return commands.content
            } else {
                return []
            }
        },
        { refetchInterval: 10000 }
    )

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <h2>Widget</h2>
                <section ref={elementRef}>
                    <ListItem key="none">
                        <ListItemText>
                            Click on a part of the SVG to show the related
                            Widget
                        </ListItemText>
                    </ListItem>
                </section>
                <h2>Commands</h2>
                <List component="section">
                    {commandsLoadIsSuccess &&
                        commands.map((command) => (
                            <ListItem key={command.id}>
                                <ListItemText primary={command.name} />
                            </ListItem>
                        ))}
                    {(!commandsLoadIsSuccess || commands.length === 0) && (
                        <ListItem key="none">
                            <ListItemText>No command available</ListItemText>
                        </ListItem>
                    )}
                </List>
                <Button variant="outlined" onClick={() => sendCommands()}>
                    Send
                </Button>
            </Grid>
            {monitorsLoadIsSuccess && (
                <Grid item xs={6}>
                    <SvgContainingMonitors
                        circle="white"
                        top={antenaState}
                        right={rightState}
                        left={leftState}
                        center={centerState}
                        clicked={setClicked}
                    />
                    <div className="center">
                        <p>White is the default value</p>
                        <p className="grey">grey is unknown</p>
                        <p className="green">green is OK</p>
                        <p className="red">red is TRIGGERED</p>
                    </div>
                </Grid>
            )}
        </Grid>
    )
}

export default MonitorAssetPage

import { useState } from 'react'
import { fetchNodes } from '../API'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useQuery } from 'react-query'

const ListAssetPage = () => {
    const [assetId, setAssetId] = useState(null)

    const { isSuccess, data } = useQuery(
        'nodes',
        async () => {
            const response = await fetchNodes()
            return response.content
        },
        { refetchInterval: 10000 }
    )

    return (
        <div>
            <h2>Assets</h2>
            <List component="nav">
                {isSuccess &&
                    data.map((asset) => (
                        <ListItem
                            key={asset.id}
                            onClick={() => setAssetId(asset.id)}
                            selected={asset.id === assetId}
                        >
                            <ListItemText primary={asset.name} />
                        </ListItem>
                    ))}
            </List>
        </div>
    )
}

export default ListAssetPage

const SvgContainingMonitors = ({ top, center, left, right, clicked }) => {
    return (
        <section>
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
            >
                <g fill="#263A7A">
                    <circle cx="256" cy="256" r="256" />
                </g>
                <g fill="#121149">
                    <path d="M509.225,293.74l-86.03-86.03l-19.089,6.309l-89.6-89.6l-96.516,30.229l76.021,95.76l-51.195,16.92  l-58.466-59.842l-59.961,98.982l-35.845,11.847L281.01,510.781c0.579-0.055,1.162-0.114,1.741-0.169  C399.084,498.531,492.26,408.55,509.225,293.74L509.225,293.74z" />
                </g>
                <g fill={left} onClick={() => clicked(1)}>
                    <path d="M172.015,323.803h-70.723c-9.766,0-17.682-7.916-17.682-17.682v-85.973   c0-9.766,7.916-17.682,17.682-17.682h70.723c9.766,0,17.682,7.916,17.682,17.682v85.973   C189.695,315.887,181.779,323.803,172.015,323.803z" />
                    <rect
                        x="183.199"
                        y="257.103"
                        width="84.374"
                        height="12.067"
                    />
                </g>
                <g fill={right} onClick={() => clicked(2)}>
                    <path d="M410.708,323.803h-70.723c-9.766,0-17.682-7.916-17.682-17.682v-85.973   c0-9.766,7.916-17.682,17.682-17.682h70.723c9.766,0,17.682,7.916,17.682,17.682v85.973   C428.391,315.887,420.474,323.803,410.708,323.803z" />
                    <rect
                        x="266.573"
                        y="257.103"
                        width="83.374"
                        height="12.067"
                    />
                </g>
                <g fill={center} onClick={() => clicked(3)}>
                    <path d="M227.624,400.294c0,5.461,4.427,9.888,9.888,9.888h36.974c5.461,0,9.888-4.427,9.888-9.888v-20.208   h-56.751V400.294z" />
                    <path d="M255.426,380.087v30.096h19.061c5.461,0,9.888-4.427,9.888-9.888v-20.208H255.426z" />
                    <path d="M224.38,396.605h63.241c9.342,0,16.913-7.573,16.913-16.913V195.113  c0-9.342-7.573-16.913-16.913-16.913H224.38c-9.342,0-16.913,7.573-16.913,16.913v184.579  C207.465,389.032,215.038,396.605,224.38,396.605z" />
                    <path d="M287.622,178.2h-32.196v218.405h32.196c9.342,0,16.913-7.573,16.913-16.913V195.113  C304.535,185.772,296.962,178.2,287.622,178.2z" />
                    <path d="M304.535,195.113c0-9.34-7.573-16.913-16.913-16.913H224.38c-9.342,0-16.913,7.573-16.913,16.913  v34.563h97.07v-34.563H304.535z" />
                    <path d="M304.535,195.113c0-9.34-7.573-16.913-16.913-16.913h-32.196v51.476h49.109V195.113z" />
                </g>
                <g fill={top} onClick={() => clicked(4)}>
                    <path d="M293.424,141.346l-33.976-50.604v-9.611c0-1.903-1.545-3.448-3.448-3.448s-3.448,1.545-3.448,3.448  v9.611l-33.976,50.604c-1.062,1.581-0.641,3.724,0.94,4.784c1.584,1.067,3.724,0.638,4.784-0.94l28.253-42.077v26.552  c0,1.903,1.545,3.448,3.448,3.448s3.448-1.545,3.448-3.448v-26.552l28.253,42.079c0.665,0.99,1.755,1.526,2.865,1.526  c0.66,0,1.329-0.188,1.919-0.586C294.066,145.07,294.486,142.927,293.424,141.346z" />
                    <path d="M293.424,141.346l-33.976-50.604v-9.611c0-1.903-1.545-3.448-3.448-3.448  c-0.197,0-0.386,0.026-0.574,0.059v55.313c0.188,0.033,0.378,0.059,0.574,0.059c1.903,0,3.448-1.545,3.448-3.448v-26.553  l28.253,42.079c0.665,0.99,1.755,1.526,2.865,1.526c0.66,0,1.329-0.188,1.919-0.586C294.066,145.07,294.486,142.927,293.424,141.346  z" />
                    <path d="M197.494,124.418c7.552,25.162,30.882,43.501,58.506,43.501s50.953-18.337,58.508-43.499  L197.494,124.418z" />
                    <path d="M255.426,124.418v43.489c0.191,0.002,0.381,0.012,0.572,0.012c27.624,0,50.953-18.337,58.508-43.499  L255.426,124.418z" />
                </g>
            </svg>
        </section>
    )
}

export default SvgContainingMonitors

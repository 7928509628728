// This is the start of your own application!
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import ListAssetPage from './ListAssetPage'
import MonitorAssetPage from './MonitorAssetPage'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

const Main = () => {
    // We use Material-UI (https://material-ui.com/getting-started/learn/) as a component library. It exposes most common low level UI elements as react components.
    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <Switch>
                    <Route path="/monitor">
                        <MonitorAssetPage />
                    </Route>
                    <Route path="/">
                        <ListAssetPage />
                    </Route>
                </Switch>
            </Router>
        </QueryClientProvider>
    )
}

export default Main

import { withAPIClient } from './helpers'

export const fetchNodes = (searchParams) =>
    withAPIClient((client) =>
        client
            .get(`asset-repository/nodes`, {
                searchParams,
            })
            .json()
    )

export const fetchMonitors = (searchParams) =>
    withAPIClient((client) =>
        client
            .get(`monitors-repository/monitors`, {
                searchParams,
            })
            .json()
    )

export const fetchCommandQueues = (searchParams) =>
    withAPIClient((client) =>
        client
            .get(`commands-repository/command-queues`, {
                searchParams,
            })
            .json()
    )

export const fetchCommands = (searchParams) =>
    withAPIClient((client) =>
        client
            .get(`commands-repository/commands`, {
                searchParams,
            })
            .json()
    )

export const fetchMonitor = (id) =>
    withAPIClient((client) =>
        client.get(`monitors-repository/monitors/${id}`).json()
    )

export const putMonitor = (id, body) =>
    withAPIClient((client) =>
        client
            .put(`monitors-repository/monitors/${id}`, {
                body: JSON.stringify(body),
                headers: { 'Content-Type': 'application/json' },
            })
            .json()
    )

export const transmitCommands = (body) =>
    withAPIClient((client) =>
        client
            .post(`commands-repository/transmissions/`, {
                body: JSON.stringify(body),
                headers: { 'Content-Type': 'application/json' },
            })
            .json()
    )

// You can add more Leanspace API calls following this pattern. The withAPIClient function returns an instance of ky (https://github.com/sindresorhus/ky) that is pre-configured with the base API URL and an up-to-date token.

export { fetchTenantInformations } from './helpers'
